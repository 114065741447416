<template>
  <el-main class="rh-table">
    <h1><i class="el-icon-setting" /> Options d'activité hors-clinique</h1>
    <p>
      Les activités hors-clinique attribuées à des médecins ne peuvent pas êtres
      supprimées.
    </p>
    <app-table
      tablenom="hcactivitetitres"
      :cols="colonnes"
      :items="hcactivitetitres"
      :loadstatus="hcactivitetitresLoadStatus"
      :deletestatus="hcactivitetitreDeleteStatus"
      :formroutes="hctFormRoutes"
      :actions="hctActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      hctFormRoutes: {
        edit: { route: "hcactivitetitreedit", params: { idhctitre: "id" } },
        create: { route: "hcactivitetitrecreate" },
      },
    };
  },
  computed: {
    hcactivitetitresLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.hc.hcactivitesLoadStatus;
    },
    hcactivitetitreDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.hc.hcactiviteDeleteStatus;
    },
    hcactivitetitres() {
      return this.$store.state.hc.hcactivites;
    },
    colonnes() {
      return this.$store.state.hc.colonnes;
    },
    hctActions() {
      return this.$store.state.hc.actions;
    },
  },
};
</script>
